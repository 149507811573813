import React from "react"
// Components
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container1200 } from '../components/Container1200';
import Layout from "../components/layout"
import SEO from "../components/seo"
// Style
import {
  Blurb,
  Heading,
  ImagePaper,
  ImageWrapper,
  Title
} from '../styles/pages-styles/index.styles';

export const query = graphql`
  query IndexQuery {
    allSanityPageHome {
      edges {
        node {
          _id
          body
        }
      }
    }
    allSanityPost(limit: 1, filter: {_createdAt: {}, imageOrientation: {eq: "h"}, mainImage: {asset: {fluid: {}}}}, sort: {order: DESC, fields: publishedAt}) {
      edges {
        node {
          _id
          imgAlt
          mainImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <SEO
        image={data.allSanityPost.edges[0].node.mainImage.asset.fluid.src}
        title="Photo Cards with Stories"
      />
        <Container1200>
          <Heading>
            <Title><span>The</span>Ambitious Beaver</Title>
            <ImageWrapper>
              <ImagePaper>
                <Img
                  fluid={data.allSanityPost.edges[0].node.mainImage.asset.fluid}
                  alt={data.allSanityPost.edges[0].node.imgAlt}
                />
              </ImagePaper>
            </ImageWrapper>
          </Heading>
          <Blurb>
            <p>{data.allSanityPageHome.edges[0].node.body}</p>
          </Blurb>
        </Container1200>
    </Layout>
  )
}

export default IndexPage
