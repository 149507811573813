import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Heading = styled.div`

  @media (min-width: 768px) {
    display: flex;
    padding: 0 60px;
    margin-top: 50px;
  }
`;

export const Title = styled.h1`
  font-family: Thasadith;
  font-size: 13.33vw;
  padding-left: 5vw;

  @media (min-width: 768px) {
    font-size: 6rem;
    width: 270px;
    padding-left: 20px;
  }

  @media (min-width: 992px) {
    font-size: 7.4rem;
    width: 340px;
  }

  @media (min-width: 1200px) {
    font-size: 8.1rem;
    width: 420px;
  }
  
  span {
    display: inline-block;
    width: 100%;
    font-size: .675em;
    color: ${c.grayText};
    margin-left: -4vw;

    @media (min-width: 768px) {
      margin-left: -16px;
    }
  }
`;

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vw 20px 0;

  @media (min-width: 768px) {
    padding: 0 0 0 50px;
  }
`;

export const ImagePaper = styled.div`
  position: relative;
  background-color: ${c.grayPaper};
  padding: 8px;
  width: 100%;
  box-shadow: 2px 3px 3px rgba(0,0,0,0.4);
  transform: rotate(4deg);
  border: 1px solid #ddd;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${c.grayPaper};
    transform: rotate(-10deg);
    z-index: 1;
    box-shadow: 1px 3px 3px rgba(0,0,0,0.4);
    border: 1px solid #ddd;
  }

  .gatsby-image-wrapper {
    z-index: 2;
    position: relative;
    width: 100%;
    height: auto;
    transform: rotate(-10deg);
  }
`;

export const Blurb = styled.div`
  width: 100%;
  max-width: 960px;
  padding: 30px;
  background-color: ${c.greenDark};
  margin: 12vw auto 10px;
  border-radius: 8px;

  @media (min-width: 992px) {
    margin: 100px auto 50px;
    padding: 60px;
  }

  p {
    font-size: 2.4rem;
    line-height: 119%;
    text-align: center;

    @media (min-width: 992px) {
      font-size: 3.6rem;
    }
  }
`;